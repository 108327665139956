import { Component, Vue, Watch } from 'vue-property-decorator'
import GtrAdminLayout from '@/modules/common/views/layouts/level-one/admin/admin.layout.vue'
import { mapState } from 'vuex'

@Component({
  name: 'GtrAdminIntegrationsView',
  computed: {
    ...mapState('syncs', ['all_syncs'])
  }
})
export default class GtrAdminIntegrationsView extends Vue {
  currentUser!: Record<string, any>

  data () {
    return {
      search: '',
      syncTablePage: 1,
      loading: false,
      table: {
        headers: [
          {
            text: 'Event',
            align: 'start',
            sortable: true,
            value: 'event_name'
          },
          {
            text: 'Company',
            value: 'company_name'
          },
          {
            text: 'Integration',
            value: 'type'
          },
          {
            text: 'Type',
            value: 'table'
          }
        ],
        items: []
      },
      companyToDelete: null,
      showActiveIntegrations: true
    }
  }

  created () {
    this.$emit('update:layout', GtrAdminLayout)
  }

  async mounted () {
    this.$data.loading = true
    await this.$store.dispatch('syncs/getAllSyncs')
    this.$data.loading = false
  }

  get activeIntegrations () {
    return this.$data.table.items.filter(sync => sync.status)
  }

  get inactiveIntegrations () {
    return this.$data.table.items.filter(sync => !sync.status)
  }

  get showIntegrationsText () {
    if (this.$data.showActiveIntegrations) {
      if (!this.inactiveIntegrations.length) {
        return ''
      }
      return `View ${this.inactiveIntegrations.length} Inactive Integration${this.inactiveIntegrations.length === 1 ? '' : 's'}`
    }
    if (!this.activeIntegrations.length) {
      return ''
    }
    return `View ${this.activeIntegrations.length} Active Integration${this.activeIntegrations.length === 1 ? '' : 's'}`
  }

  private toggleShowActiveIntegrations () {
    this.$data.showActiveIntegrations = !this.$data.showActiveIntegrations
    this.$data.search = ''
    this.$data.syncTablePage = 1
  }

  private getCompanyLogo (item: any) {
    try {
      if (item.company_name.toLowerCase() === 'gtr') {
        return require('@/assets/img/gtr-logo-blue.svg')
      }
      return item.company_logo
    } catch (e) {
      return ''
    }
  }

  private getSyncLogo (type: string) {
    const syncTypesImageMap = {
      aventri: 'aventri-logo.svg',
      bizzabo: 'bizzabo-logo.svg',
      configio: 'configio-logo.svg',
      mctrade: 'mctrade-logo.svg',
      eventbrite: 'eventbrite-logo.svg',
      eventsquid: 'eventsquid-logo.svg',
      hubspot: 'hubspot-logo.svg',
      jot: 'jot-logo.png',
      marketo: 'marketo-logo.svg',
      pardot: 'salesforce-pardot-logo.svg',
      pathable: 'pathable-logo.svg',
      swapcard: 'swapcard-logo.svg',
      zapier: 'zapier-logo.svg'
    }
    const typeFormatted = type.toLowerCase()
    try {
      for (const key in syncTypesImageMap) {
        if (typeFormatted.indexOf(key) === 0) {
          return require(`@/assets/img/integrations/${syncTypesImageMap[key]}`)
        }
      }
      return ''
    } catch (e) {
      return ''
    }
  }

  @Watch('all_syncs')
  onAllSyncsChange (payload: any[]) {
    if (Array.isArray(payload) && payload.length) {
      this.$data.table.items = payload.map((item, index) => {
        item.type = item.type.charAt(0).toUpperCase() + item.type.slice(1)
        item.table = item.table.charAt(0).toUpperCase() + item.table.slice(1)
        item.itemKey = `${index}`
        return item
      })
    }
  }

  get syncTableItems () {
    if (this.$data.showActiveIntegrations) {
      return this.activeIntegrations
    }
    return this.inactiveIntegrations
  }

  get pageTitle () {
    if (this.$data.showActiveIntegrations) {
      return 'Active Integrations'
    }
    return 'Inactive Integrations'
  }
}
